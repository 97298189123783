
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { loginWithFacebook } from '@/core/services/FacebookService';

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string()
        .required()
        .label("First Name"),
      last_name: Yup.string()
        .required()
        .label("Last Name"),
      email: Yup.string()
        .min(4)
        .required()
        .email()
        .label("Email"),
      password: Yup.string()
        .min(6)
        .required()
        .label("Password"),
      password_confirmation: Yup.string()
        .min(6)
        .required()
        .test('passwords-match', 'Passwords must match', function(value){
          return this.parent.password === value
        })
        .label("Password Confirmation"),
      terms_and_conditions: Yup.boolean()
        .required()
        .label("Terms and conditions"),
    });

    const onSubmitRegister = values => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send register request
      store
        .dispatch(Actions.REGISTER, values)
        .then(() => {

          Swal.fire({
            text: "Your account was created, confirmation email sent.<br/>Please confirm your email",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            // Redirect after successful sign up
            router.push({ name: "sign-in" });
          });

        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loginWithFacebook,
    };
  }
});
