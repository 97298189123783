<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img alt="Logo" src="/media/logos/logo-algoma-square-256.png" class="h-80px" />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          novalidate="novalidate"
          @submit="onSubmitRegister"
          id="kt_login_signup_form"
          :validation-schema="registration"
        >
          <!--begin::Heading-->
          <div class="mb-10 text-center">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">
              Create an Account
            </h1>
            <!--end::Title-->

            <!--begin::Link-->
            <div class="text-gray-400 fw-bold fs-4">
              Already have an account?

              <router-link to="/sign-in" class="link-primary fw-bolder">
                Sign in here
              </router-link>
            </div>
            <!--end::Link-->
          </div>
          <!--end::Heading-->

          <!--begin::Action-->
          <button
            type="button"
            class="btn btn-flex btn-outline btn-outline-default flex-center btn-lg w-100 mb-5"
            style="border-color: rgb(24, 119, 242);border-width: 2px;color: rgb(24, 119, 242);font-weight: bold;"
            @click="loginWithFacebook"
          >
           <img
             alt="Logo"
             src="/media/facebook/f_logo_RGB-Blue_58.png"
             class="h-30px me-3"
           />
           Continue with Facebook
          </button>
          <!--end::Action-->

          <!--begin::Separator-->
          <div class="d-flex align-items-center mb-10">
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
            <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
          </div>
          <!--end::Separator-->

          <!--begin::Input group-->
          <div class="row fv-row mb-7">
            <!--begin::Col-->
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6"
                >First Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder=""
                name="first_name"
                autocomplete="off"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="first_name" />
                </div>
              </div>
            </div>
            <!--end::Col-->

            <!--begin::Col-->
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6"
                >Last Name</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                placeholder=""
                name="last_name"
                autocomplete="off"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="last_name" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="fv-row mb-7">
            <label class="form-label fw-bolder text-dark fs-6">Email</label>
            <Field
              class="form-control form-control-lg form-control-solid"
              type="email"
              placeholder=""
              name="email"
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="mb-10 fv-row" data-kt-password-meter="true">
            <!--begin::Wrapper-->
            <div class="mb-1">
              <!--begin::Label-->
              <label class="form-label fw-bolder text-dark fs-6">
                Password
              </label>
              <!--end::Label-->

              <!--begin::Input wrapper-->
              <div class="position-relative mb-3">
                <Field
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  placeholder=""
                  name="password"
                  autocomplete="off"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="password" />
                  </div>
                </div>
              </div>
              <!--end::Input wrapper-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Input group--->

          <!--begin::Input group-->
          <div class="fv-row mb-5">
            <label class="form-label fw-bolder text-dark fs-6"
              >Confirm Password</label
            >
            <Field
              class="form-control form-control-lg form-control-solid"
              type="password"
              placeholder=""
              name="password_confirmation"
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password_confirmation" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <label class="form-check form-check-custom form-check-solid">
              <Field
                class="form-check-input"
                type="checkbox"
                name="terms_and_conditions"
                value="1"
              />
              <span class="form-check-label fw-bold text-gray-700 fs-6">
                I Agree &
                <a href="https://www.algoma.io/terms-of-service" target="_blank" class="ms-1 link-primary">Terms and conditions</a>.
              </span>
            </label>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="terms_and_conditions" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <button
              id="kt_sign_up_submit"
              ref="submitButton"
              type="submit"
              class="btn btn-lg btn-primary"
            >
              <span class="indicator-label">
                Submit
              </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { loginWithFacebook } from '@/core/services/FacebookService';

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string()
        .required()
        .label("First Name"),
      last_name: Yup.string()
        .required()
        .label("Last Name"),
      email: Yup.string()
        .min(4)
        .required()
        .email()
        .label("Email"),
      password: Yup.string()
        .min(6)
        .required()
        .label("Password"),
      password_confirmation: Yup.string()
        .min(6)
        .required()
        .test('passwords-match', 'Passwords must match', function(value){
          return this.parent.password === value
        })
        .label("Password Confirmation"),
      terms_and_conditions: Yup.boolean()
        .required()
        .label("Terms and conditions"),
    });

    const onSubmitRegister = values => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send register request
      store
        .dispatch(Actions.REGISTER, values)
        .then(() => {

          Swal.fire({
            text: "Your account was created, confirmation email sent.<br/>Please confirm your email",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary"
            }
          }).then(function() {
            // Redirect after successful sign up
            router.push({ name: "sign-in" });
          });

        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger"
            }
          });
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loginWithFacebook,
    };
  }
});
</script>
